import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  companyName: {
    id: "OTA.chooseAccom.companyName",
    defaultMessage: "OHMYHOTEL & CO!"
  },
  header: {
    id: "OTA.chooseAccom.header",
    defaultMessage: "Choosing accommodation a new point of view "
  },
  subHeader: {
    id: "OTA.chooseAccom.subHeader",
    defaultMessage:
      "<h5>Shall we look around?</h5><img className='company-name-img' src='/images/logo/name-black-exclamation.svg' height='17' alt='' />"
  },
  commonContent: {
    id: "OTA.chooseAccom.commonContent",
    defaultMessage:
      "Find the place you want to travel to and find the accommodation you want to stay with.<br />The Ohmyhotel helps you relax in your valuable trip."
  },
  titte1: {
    id: "OTA.chooseAccom.titte1",
    defaultMessage: "It`s easy"
  },
  titte2: {
    id: "OTA.chooseAccom.titte2",
    defaultMessage: "It`s cheap"
  },
  titte3: {
    id: "OTA.chooseAccom.titte3",
    defaultMessage: "It`s done"
  },
  content1: {
    id: "OTA.chooseAccom.content1",
    defaultMessage: "A good-looking design is easy to search."
  },
  content2: {
    id: "OTA.chooseAccom.content2",
    defaultMessage:
      "It's cheap by direct contract with the hotel.<br/>I even find hidden rooms."
  },
  content3: {
    id: "OTA.chooseAccom.content3",
    defaultMessage:
      "It's important. It's essential.<br/>Then, the reservation is fast like this way."
  }
});

class OTAChooseAccom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="choose-accom-section">
        <Fade distance="2rem" bottom>
          <div className="section-header align-center">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </div>
        </Fade>
        <Container className="section-content">
          <Fade distance="2rem" bottom>
            <FormattedHTMLMessage {...messages.subHeader} />
            {/* <h5>{formatMessage(messages.subHeader)}</h5>
            <h4>
              <img
                className="company-name-img"
                src="/images/logo/name-black-exclamation.svg"
                height="17"
                alt=""
              />
            </h4> */}
            <FormattedHTMLMessage tagName="p" {...messages.commonContent} />
          </Fade>

          <Row>
            <Col md="4">
              <Fade distance="2rem" bottom>
                <div className="align-center">
                  <div className="p-content">
                    <h5 className="p-title">
                      {formatMessage(messages.titte1)}
                    </h5>
                    <FormattedHTMLMessage tagName="p" {...messages.content1} />
                    <picture>
                      <source
                        media="(max-width: 767.98px)"
                        srcSet="/images/business/OTA/m-new-easy.svg"
                      />
                      <img src="/images/business/OTA/m-new-easy.svg" alt="" />
                    </picture>
                  </div>

                </div>
              </Fade>
            </Col>
            <Col md="4">
              <Fade distance="2rem" bottom>
                <div className="align-center">
                  <div className="p-content">
                    <h5 className="p-title">
                      {formatMessage(messages.titte2)}
                    </h5>
                    <FormattedHTMLMessage tagName="p" {...messages.content2} />
                    <picture>
                      <source
                        media="(max-width: 767.98px)"
                        srcSet="/images/business/OTA/m-new-cheap.svg"
                      />
                      <img src="/images/business/OTA/m-new-cheap.svg" alt="" />
                    </picture>
                  </div>

                </div>
              </Fade>
            </Col>
            <Col md="4">
              <Fade distance="2rem" bottom>
                <div className="align-center">
                  <div className="p-content">
                    <h5 className="p-title">
                      {formatMessage(messages.titte3)}
                    </h5>
                    <FormattedHTMLMessage tagName="p" {...messages.content3} />
                    <picture>
                      <source
                        media="(max-width: 767.98px)"
                        srcSet="/images/business/OTA/m-new-done.svg"
                      />
                      <img src="/images/business/OTA/m-new-done.svg" alt="" />
                    </picture>
                  </div>

                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default injectIntl(OTAChooseAccom);
