import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CompanyProfile.intro.header",
    defaultMessage: "Hotel Management"
  },
  contentCommon: {
    id: "CompanyProfile.intro.contentCommon",
    defaultMessage:
      "Ohmyhotel & Co offers unique hotel operation know-how and details. Based on own hotel booking system, we signed general service administration contracts with distinguished hotels in Korea and Japan. Entering the contracts, we managed and sold the booking system for the hotels. We promise to support our customers with care based on our approaching services."
  },
});
class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    const locale = window.localStorage.getItem("__lang__")
      ? JSON.parse(window.localStorage.getItem("__lang__")).code
      : "ja";

    var fileNameHotel = "/OHMYHOTEL_Company_Profile(Hotel)_2024.pdf";
    var fileNameChannel = "/Ohmyhotel_Channel_Sales_Proposal_240508.pdf";
    if (locale) { fileNameHotel = `/${locale}` +  fileNameHotel; fileNameChannel = `/${locale}` + fileNameChannel }
    else { fileNameHotel = "/en" +  fileNameHotel; fileNameChannel = "/en" + fileNameChannel }
    return (
      <section className="cp-section">
        <Fade distance="2rem" bottom>
          <div className="section-header container">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </div>
          <Container>
            <Row className="section-content">
              <Col
                className="align-left"
                lg={{ size: 12, order: 1 }}
                md={{ size: 12, order: 2 }}
                sm={{ size: 12, order: 2 }}
                xs={{ size: 12, order: 2 }}
              >
                <FormattedHTMLMessage tagName="p" {...messages.contentCommon} />
              </Col>
            </Row>
            <Row className="section-content">
              <Col
                lg={{ size: 6, order: 1 }}
                md={{ size: 12, order: 2 }}
                sm={{ size: 12, order: 2 }}
                xs={{ size: 12, order: 2 }}
              >
                <a
                  href={"/images/business/CP/hotel" + fileNameHotel}
                  download="OHMYHOTEL_Company_Profile(Hotel)"
                  className="btn btn-primary"
                >
                  <img src="/images/about/download.svg" alt="" width="18" />
                  <span>For the Hotel - Download</span>
                </a>
              </Col>
              <Col
                lg={{ size: 6, order: 1 }}
                md={{ size: 12, order: 2 }}
                sm={{ size: 12, order: 2 }}
                xs={{ size: 12, order: 2 }}
              >
                <a
                  href={"/images/business/CP/channel" + fileNameChannel}
                  download="Ohmyhotel_Channel_Sales_Proposal"
                  className="btn btn-primary"
                >
                  <img src="/images/about/download.svg" alt="" width="18" />
                  <span>For the Channel - Download</span>
                </a>
              </Col>
            </Row>
          </Container>


        </Fade>
      </section>
    );
  }
}

export default injectIntl(CompanyProfile);
