import React from "react";
import BusinessIntro from "../layout/pages/BusinessPage/BusinessIntro";
import OTA from "../layout/pages/BusinessPage/OTA";
import BE from "../layout/pages/BusinessPage/BE";
import PMS from "../layout/pages/BusinessPage/PMS";
import CMS from "../layout/pages/BusinessPage/CMS";
//  import WOC from "../layout/pages/BusinessPage/WOC";
//  import SYSSaleChannel from "../layout/pages/BusinessPage/SYS-Sales-Channel";
//  import PARTNER from "../layout/pages/BusinessPage/PARTNER";
//  import OMTBIZ from "../layout/pages/BusinessPage/OMTBIZ";
// import HM from "../layout/pages/BusinessPage/HM";
import CP from "../layout/pages/BusinessPage/CP";
import { Route, Switch } from "react-router-dom";

class BusinessRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const url = this.props.url;
    return (
      <Switch>
        <Route path={`${url}/intro`} component={BusinessIntro} />
        <Route path={`${url}/online-booking-engine`} component={OTA} />
        <Route path={`${url}/hotel-web`} component={BE} />
        <Route path={`${url}/pms`} component={PMS} />
        <Route path={`${url}/cms`} component={CMS} />
        <Route path={`${url}/company-profile`} component={CP} />
        {/* <Route path={`${url}/woc`} component={WOC} />
        <Route path={`${url}/sys-sales-channel`} component={SYSSaleChannel} />
        <Route path={`${url}/partner`} component={PARTNER} />
        <Route path={`${url}/omtbiz`} component={OMTBIZ} /> */}
        {/* <Route path={`${url}/hotel-management`} component={HM} /> */}
      </Switch>
    );
  }
}

export default BusinessRouter;
