import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

import "./History.scss";

const messages = defineMessages({
  header: {
    id: "aboutUs.history.header",
    defaultMessage: "History"
  },
  item1: {
    id: "aboutUs.history.item1",
    defaultMessage: "Established Ohmyhotel & Co (Japan, Vietnam)"
  },
  item2: {
    id: "aboutUs.history.item2",
    defaultMessage: "Formed strategic business partnership with ROONETS PMS"
  },
  item3: {
    id: "aboutUs.history.item3",
    defaultMessage:
      "Established NDC Hospitality in HCMC, Vietnam, in line with foray into Vietnam hotel development business"
  },
  item4: {
    id: "aboutUs.history.item4",
    defaultMessage: "Established Orenge Partners Holdings in Osaka"
  },
  item5: {
    id: "aboutUs.history.item5",
    defaultMessage: "Established Ohmytrip and launched brand"
  },
  item6: {
    id: "aboutUs.history.item6",
    defaultMessage: "Established Orenge Partners Holdings in Osaka"
  },
  item7: {
    id: "aboutUs.history.item7",
    defaultMessage:
      "Awarded prestigious Taiwan Tourism Award by Taiwan Tourism Bureau"
  },
  item8: {
    id: "aboutUs.history.item8",
    defaultMessage: "Established Ezget (IT Corporate)"
  },
  item9: {
    id: "aboutUs.history.item9",
    defaultMessage:
      "Appointed Policy Advisor for Secretary to President of Tourism"
  },
  item10: {
    id: "aboutUs.history.item10",
    defaultMessage:
      "Acquired Bico and arranged basis for global business expansion centering on Bico Trip’s Seoul head office"
  },
  item11: {
    id: "aboutUs.history.item11",
    defaultMessage: "Expanded business"
  },
  item12: {
    id: "aboutUs.history.item12",
    defaultMessage:
      "Founded Bico Trip branches (Hong Kong, Shanghai, Singapore, Thailand, Taiwan, Philippines)"
  },
  item13: {
    id: "aboutUs.history.item13",
    defaultMessage: "Awarded “Japan Tourism Agency Commissioner’s Commendation“"
  },
  item14: {
    id: "aboutUs.history.item14",
    defaultMessage: "Established Bico Trip (Korean Corporate)"
  },
  item15: {
    id: "aboutUs.history.item15",
    defaultMessage: "Established Bico (Japanese Corporate"
  },
  item16: {
    id: "aboutUs.history.item16",
    defaultMessage: "Relocated OhMyHotel&Co. headquarters from Vietnam to Seoul"
  },
  item17: {
    id: "aboutUs.history.item17",
    defaultMessage: "Launched Ohmyhotel.com"
  },
  item18: {
    id: "aboutUs.history.item18",
    defaultMessage: "Awarded Trip.com 2023 Performance Excellence Partner"
  },
  item19: {
    id: "aboutUs.history.item19",
    defaultMessage: "Launched Ohmytrip.com"
  },
  item20: {
    id: "aboutUs.history.item20",
    defaultMessage: "Awarded Trip.com 2024 Performance Excellence Partner"
  },
  item21: {
    id: "aboutUs.history.item21",
    defaultMessage: "Awarded the 2024 'Tourism Day' Prime Minister's Award"
  }
});
class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <div>
        <section className="history-section">
          <Fade distance="2rem" bottom>
            <Container>
              <div className="section-header">
                <h3>{formatMessage(messages.header)}</h3>
                <span className="header-line" />
              </div>
              <div className="section-content align-left">
              <Row className="year-name-sm">
                  <Col>2024</Col>
                  <Col>09</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col><FormattedHTMLMessage tagName="span" {...messages.item21} /></Col>
                </Row>
                <Row className="year-name-sm">
                  <Col />
                  <Col>05</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/international.png" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item20)}</Col>
                </Row>
                <Row className="year-name-sm">
                  <Col />
                  <Col>01</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item19)}</Col>
                </Row>
                <Row className="year-name-sm">
                  <Col>2023</Col>
                  <Col>09</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/international.png" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item18)}</Col>
                </Row>
                <Row className="last-of-year">
                  <Col />
                  <Col>06</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/international.png" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item17)}</Col>
                </Row>
                <Row className="year-name-sm">
                  <Col>2021</Col>
                  <Col>12</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item16)}</Col>
                </Row>
                <Row className="year-name-sm">
                  <Col>2018</Col>
                  <Col>07</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/international.png" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item1)}</Col>
                </Row>
                <Row className="last-of-year">
                  <Col />
                  <Col>06</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item2)}</Col>
                </Row>

                <Row className="year-name-sm">
                  <Col>2017</Col>
                  <Col>11</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/vi.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item3)}</Col>
                </Row>
                <Row className="last-of-year">
                  <Col />
                  <Col>06</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ja.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item4)}</Col>
                </Row>

                <Row className="year-name-sm">
                  <Col>2015</Col>
                  <Col>11</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item5)}</Col>
                </Row>
                <Row className="last-of-year">
                  <Col />
                  <Col />
                  <Col />
                  <Col>{formatMessage(messages.item6)}</Col>
                </Row>
                <Row className="last-of-year year-name-sm">
                  <Col>2013</Col>
                  <Col>03</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/taiwan.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item7)}</Col>
                </Row>
                <Row className="year-name-sm">
                  <Col>2012</Col>
                  <Col>05</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item8)}</Col>
                </Row>
                <Row className="last-of-year">
                  <Col />
                  <Col>02</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item9)}</Col>
                </Row>

                <Row className="last-of-year year-name-sm">
                  <Col>2011</Col>
                  <Col>04</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item10)}</Col>
                </Row>

                <Row className="year-name-sm">
                  <Col>2010</Col>
                  <Col />
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/international.png" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item11)}</Col>
                </Row>
                <Row className="last-of-year">
                  <Col />
                  <Col />
                  <Col />
                  <Col>{formatMessage(messages.item12)}</Col>
                </Row>

                <Row className="last-of-year year-name-sm">
                  <Col>2009</Col>
                  <Col>12</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ja.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item13)}</Col>
                </Row>

                <Row className="last-of-year year-name-sm">
                  <Col>2006</Col>
                  <Col>05</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ko.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item14)}</Col>
                </Row>

                <Row className=" year-name-sm">
                  <Col>1997</Col>
                  <Col>03</Col>
                  <Col>
                    <span className="lang-flag">
                      <img src="/images/flags/ja.svg" alt="" />
                    </span>
                  </Col>
                  <Col>{formatMessage(messages.item15)}</Col>
                </Row>
              </div>
            </Container>
          </Fade>
        </section>
      </div>
    );
  }
}

export default injectIntl(History);
