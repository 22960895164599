import React from "react";
import { injectIntl, defineMessages, FormattedHTMLMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { Fade } from "react-reveal";

const messages = defineMessages({
  header: {
    id: "CI.colorSystem.header",
    defaultMessage: "Color System"
  },
  reference: {
    id: "CI.colorSystem.reference",
    defaultMessage: "Reference"
  },
  gradientView: {
    id: "CI.colorSystem.gradientView",
    defaultMessage: "Gradient View"
  },
  content: {
    id: "CI.colorSystem.content",
    defaultMessage:
      "Ohmyhotel & Co colors are a key factor in establishing corporate identity.<br/> For effective use, the designated colors should be used in accordance to the characteristics of the medium. Upon significant differences in brightness, chroma or other aspects, the color should be as close to pantone color as possible."
  }
});
class ColorSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <section className="color-system-section">
        <Fade distance="2rem" bottom>
          <Container className="section-header">
            <h3>{formatMessage(messages.header)}</h3>
            <span className="header-line" />
          </Container>
          <div className="section-content">
            <Container>
              <FormattedHTMLMessage tagName="p" {...messages.content} />
              <div className="title-comp">
                <div>Ohmyhotel & Co</div>
                <div>
                  Corporate Identity Basic System
                </div>
              </div>
              <div className="content-center">
                <Col sm="6" md="2" className="color-item">
                  <div className="color-cycle orange-gradient" />
                  <div className="orange-gradient">
                    <p>
                      {formatMessage(messages.reference)}
                    </p>
                    <p>
                      {formatMessage(messages.gradientView)}
                    </p>
                  </div>
                </Col>
                <Col sm="6" md="2" className="color-item">
                  <div className="color-cycle green-gradient" />
                  <div className="green-gradient">
                    <p>
                      {formatMessage(messages.reference)}
                    </p>
                    <p>
                      {formatMessage(messages.gradientView)}
                    </p>
                  </div>
                </Col>
                <Col sm="6" md="2" className="color-item">
                  <div className="color-cycle orange" />
                  <div>
                    <p>
                      CMYK: 00/77/100/00
                    </p>
                    <p>
                      RGB: 255/96/0
                    </p>
                    <p>
                      #ff6000
                    </p>
                  </div>
                </Col>
                <Col sm="6" md="2" className="color-item">
                  <div className="color-cycle black" />
                  <div>
                    <p>
                      CMYK: 69/62/61/54
                    </p>
                    <p>
                      RGB: 56/56/56
                    </p>
                    <p>
                      #383838
                    </p>
                  </div>
                </Col>
                <Col sm="6" md="2" className="color-item">
                  <div className="color-cycle white" />
                  <div>
                    <p>
                      CMYK: 0/1/2/0
                    </p>
                    <p>
                      RGB: 252/252/248
                    </p>
                    <p>
                      #ffcf8
                    </p>
                  </div>
                </Col>
                <Col sm="6" md="2" className="color-item">
                  <div className="color-cycle green" />
                  <div>
                    <p>
                      CMYK: 98/12/100/02
                    </p>
                    <p>
                      RGB: 00/149/5
                    </p>
                  </div>
                </Col>
              </div>
            </Container>
          </div>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(ColorSystem);
