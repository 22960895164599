import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { Link } from "react-router-dom";

import "./Footer.scss";

const messages = defineMessages({
  aboutUs: {
    id: "header.item2",
    defaultMessage: "About us"
  },
  business: {
    id: "header.item3",
    defaultMessage: "Business"
  },
  careers: {
    id: "header.item4",
    defaultMessage: "Careers"
  },
  location: {
    id: "aboutUs.submenu.item5",
    defaultMessage: "Location"
  },
  contactUs: {
    id: "footer.contactUs",
    defaultMessage: "Contact us"
  },
  privacy: {
    id: "footer.privacy",
    defaultMessage: "Privacy Policy"
  },
  address: {
    id: "footer.address",
    defaultMessage:
      "6F, GT Dongdaemun B/D, 328, Jong-ro, Jongno-gu, Seoul, Republic of Korea"
  },
  tel: {
    id: "footer.tel",
    defaultMessage:
      "+82-2-733-0550"
  },
  fax: {
    id: "footer.fax",
    defaultMessage:
      "+82-2-733-0551"
  }
});
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <div className="footer">
        <div className="container">
          <div className="logo">
            <img src="/images/footer/logo-gray.svg" alt="Ohmyhotel & CO" />
          </div>
          <div className="info align-left">
            <div className="footer-links">
              <div>
                <div>
                  <Link to="/about-us/ceo-message">
                    {formatMessage(messages.aboutUs)}
                  </Link>
                </div>
                <div>
                  <Link to="/business/online-booking-engine">
                    {formatMessage(messages.business)}
                  </Link>
                </div>
                <div>
                  <Link to="/careers/desired-traits">
                    {formatMessage(messages.careers)}
                  </Link>
                </div>
              </div>
              <div>
                <div>
                  <a href="mailto:CS@ohmyhotel.com">
                    {formatMessage(messages.contactUs)}
                  </a>
                </div>
                <div>
                  <Link to="/about-us/location">
                    {formatMessage(messages.location)}
                  </Link>
                </div>
                <div>
                  <Link to="/privacy-policy">
                    {formatMessage(messages.privacy)}
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <span className="icon-container">
                <img src="/images/footer/location.svg" alt="" />
              </span>
              {formatMessage(messages.address)}
            </div>
            <div className="content-left">
              <div>
                <span className="icon-container">
                  <img src="/images/footer/phone.svg" alt="" />
                </span>
                <span>{formatMessage(messages.tel)}</span>
              </div>
              <div className="footer-fax">
                <span className="icon-container">
                  <img src="/images/footer/fax.svg" alt="" />
                </span>
                <span>{formatMessage(messages.fax)}</span>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/ohmyhotelncovietnam"
                  target="_blank"
                  className="content-center"
                  rel="noopener noreferrer"
                >
                  <span className="icon-container">
                    <img src="/images/icons/facebook.svg" alt="" />
                  </span>
                  <span>Ohmyhotelnco</span>
                </a>
              </div>
            </div>
            <p>Copyright &copy; Ohmyhotel & Co. Ltd. All Rights Reserved.</p>
            <div className="content-left social">
              <div>
                <a href="https://www.instagram.com/ohmytrip_by_ohmyhotel/" target="blank">
                  <span>
                    <img src="/images/footer/instagram.svg" alt="" height="35" />
                  </span>
                </a>
              </div>
              <div>
                <a href="https://blog.naver.com/ohmyhotelnco" target="blank">
                  <span>
                    <img src="/images/footer/naver-blog.svg" alt="" height="35" />
                  </span>
                </a>
              </div>
              <div>
                <a href="https://kr.linkedin.com/company/ohmyhotel-co" target="blank">
                  <span>
                    <img src="/images/footer/linkedIn.svg" alt="" height="35" />
                  </span>
                </a>
              </div>
              <div>
                <a href="https://ohmypartners.notion.site/4f3bbe4586b1443897aa925e82f89190" target="blank">
                  <span>
                    <img src="/images/footer/omt-partner.svg" alt="" height="35" />
                  </span>
                </a>
              </div>
              <div>
                <a href="http://pf.kakao.com/_Pxduwxj" target="blank">
                  <span>
                    <img src="/images/footer/kakao.svg" alt="" height="35" />
                  </span>
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Footer);
