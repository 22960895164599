import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import BusinessRouter from "../../../routers/BusinessRouter";
import SubMenu from "../../components/SubMenu";
import BusinessBanner from "./BusinessBanner";

import "./BusinessPage.scss";

const messages = defineMessages({
  item1: {
    id: "business.submenu.item1",
    defaultMessage: "Online Booking Engine"
  },
  item2: {
    id: "business.submenu.item2",
    defaultMessage: "Booking Engine"
  },
  item3: {
    id: "business.submenu.item3",
    defaultMessage: "PMS"
  },
  item4: {
    id: "business.submenu.item4",
    defaultMessage: "CMS"
  },
  item5: {
    id: "business.submenu.item5",
    defaultMessage: "Hotel Management"
  }
  // item6: {
  //   id: "business.submenu.item6",
  //   defaultMessage: "Why Ohmyhotel&Co?"
  // },
  // item7: {
  //   id: "business.submenu.item7",
  //   defaultMessage: "System & Sales Channel for Hotels"
  // },
  // item8: {
  //   id: "business.submenu.item8",
  //   defaultMessage: "Partners List"
  // },
  // item9: {
  //   id: "business.submenu.item9",
  //   defaultMessage: "omtbiz"
  // },
  // item5: {
  //   id: "business.submenu.item5",
  //   defaultMessage: "Hotel Management"
  // }
});

class BusinessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let target = window.location.hash;
    if (target) {
      setTimeout(function () {
        let objControl = document.getElementById(target.substring(1));
        objControl.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
        setTimeout(function () {
          window.scrollBy({
            top: -100,
            behavior: "smooth"
          });
        }, 500);
      }, 100);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    const {
      intl: { formatMessage }
    } = this.props;
    const { match } = this.props;
    const items = [
      {
        path: "online-booking-engine",
        name: formatMessage(messages.item1)
      },
      {
        path: "hotel-web",
        name: formatMessage(messages.item2)
      },
      {
        path: "pms",
        name: formatMessage(messages.item3)
      },
      {
        path: "cms",
        name: formatMessage(messages.item4)
      },
      {
        path: "company-profile",
        name: formatMessage(messages.item5)
      }
      // {
      //   path: "woc",
      //   name: formatMessage(messages.item6)
      // },
      // {
      //   path: "sys-sales-channel",
      //   name: formatMessage(messages.item7)
      // },
      // {
      //   path: "partner",
      //   name: formatMessage(messages.item8)
      // },
      // {
      //   path: "omtbiz",
      //   name: formatMessage(messages.item9)
      // },
      // {
      //   path: "hotel-management",
      //   name: formatMessage(messages.item5)
      // }
    ];
    return (
      <div>
        <BusinessBanner />
        <SubMenu items={items} match={match} />
        <BusinessRouter url={match.url} />
      </div>
    );
  }
}

export default injectIntl(BusinessPage);
