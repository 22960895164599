import React from "react";
import CompanyProfile from "./CompanyProfile";

import "./CP.scss";
class CP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <CompanyProfile />
      </div>
    );
  }
}

export default CP;
