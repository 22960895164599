import React from "react";
import { FormattedHTMLMessage, defineMessages, injectIntl } from "react-intl";
import { Fade } from "react-reveal";
import { Col, Container, Row } from "reactstrap";

const messages = defineMessages({
  header1: {
    id: "OTA.partner.header1",
    defaultMessage: "Visit the",
  },
  websiteLink: {
    id: "OTA.partner.websiteLink",
    defaultMessage: "Ohmyhotel website ",
  },
  learnMore: {
    id: "OTA.partner.learnMore",
    defaultMessage: "Learn More",
  },
  header2: {
    id: "OTA.partner.header2",
    defaultMessage: "Ohmyhotel partner",
  },
  content: {
    id: "OTA.partner.content",
    defaultMessage:
      "I'd like to sell our hotel, too. <br/>I want to partner with our online channel. <br/>I'm interested in system purchases.",
  },
  titte: {
    id: "OTA.partner.titte",
    defaultMessage: "Would you be our partner?",
  },
  askForEmail: {
    id: "OTA.partner.askForEmail",
    defaultMessage: "Ask for mail",
  },
});
class OTAPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    let imagesHotel = [4, 30];

    const imagePartnerHotel = [];
    for (let i = imagesHotel[0]; i <= imagesHotel[1]; i++) {
      imagePartnerHotel.push(
        <img key={i} src={'/images/business/OTA/partner/hotel/image' + i + '.jpg'} alt={'partner' + i} />
      );
    }

    let imagesService = [32, 52];

    const imagePartnerService = [];
    for (let i = imagesService[0]; i <= imagesService[1]; i++) {
      imagePartnerService.push(
        <img key={i} src={'/images/business/OTA/partner/service/image' + i + '.jpg'} alt={'partner' + i} />
      );
    }
    return (
      <section className="ota-partner-section">
        <Fade distance="2rem" bottom>
          <Container>
            <Row>
              <Col md="6" sm="12">
                <div className="section-content">
                  <img
                    src="/images/business/OTA/hbe-logo.svg"
                    alt="website"
                    height="88"
                    width="66"
                  />
                  <div className="align-left">
                    <div className="p-title">
                      <FormattedHTMLMessage {...messages.header1} />
                      {/* //{formatMessage(messages.header1)} */}
                    </div>

                    <h5>
                      <a
                        href="https://www.ohmyhotel.com/"
                        className=""
                        target="_blank"
                        rel="noopener noreferrer">
                        {formatMessage(messages.websiteLink)}
                        <img
                          src="/images/icons/chevron.svg"
                          alt=">"
                          height="16"
                        />
                      </a>
                    </h5>
                    <span className="header-line content-left" />
                  </div>
                </div>
              </Col>
              <Col md="6" sm="12" className="align-left">
                <div className="section-header">
                  <h3>{formatMessage(messages.header2)}</h3>
                  <span className="header-line content-left" />
                </div>
                <div className="section-content">
                  <FormattedHTMLMessage tagName="p" {...messages.content} />
                  <h6 className="p-title">{formatMessage(messages.titte)}</h6>
                  <a href="mailto:cs@ohmyhotel.com" className="btn btn-primary">
                    <img src="/images/icons/new-email-outline.svg" alt="" />
                    {formatMessage(messages.askForEmail)}
                    <img src="/images/icons/chevron-white.svg" alt=">" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <Fade distance="2rem" bottom>
            <div style={{ paddingTop: '5rem' }}>
              <Container>
                <Row>
                  <Col md="6" sm="12">
                    <div className="section-header">
                      <h4>Global Hotel Partners</h4>
                      <span className="header-line"></span>
                    </div>
                    <div className="section-content">
                      <div className="hotel-partner">
                        {imagePartnerHotel}
                      </div>
                    </div>
                  </Col>
                  <Col md="6" sm="12">
                    <div className="section-header">
                      <h4>Global Service Partners</h4>
                      <span className="header-line"></span>
                    </div>
                    <div className="section-content">
                      <div className="hotel-partner">
                        {imagePartnerService}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

          </Fade>
        </Fade>
      </section>
    );
  }
}

export default injectIntl(OTAPartner);
